// CheckoutButton.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { getDeviceId } from '../../utils/deviceId';
import data from '../../data.json'
import { getAffiliateCode } from '../../utils/affiliateCode';

const { publishableKey, publishableTestKey } = data;

const CheckoutButton = ({ priceId, alt, testMode, promoCode }) => {

    const ref = getAffiliateCode();


    const stripePromise = loadStripe(
        testMode ? publishableTestKey : publishableKey
    );  // Replace with your Stripe Publishable Key

    const handleClick = async () => {
        const stripe = await stripePromise;
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            alert('You need to log in to upgrade.');
            return;
        }

        const userId = user.uid;
        // Call your backend to create the Checkout Session
        try {

            const response = await fetch('https://api.alphatwin.ai/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    priceId, userId, ref, url: window.location.href, deviceID: getDeviceId(),
                    promoCode: promoCode ? promoCode : null
                }),
            });

            const data = await response.json();

            if (data.error) {
                console.error(data.error);
                alert('An error occurred. Please try again.');
                return;
            }

            const sessionId = data.sessionId;

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error(error);
                alert('An error occurred during redirect.');
            }
        } catch (error) {
            console.error(error)
        }
    };
    if (alt == 1) return <button style={{
        cursor: "pointer"
    }} className='sub' onClick={handleClick}>Subscribe</button>
    if (alt == 2) return <button
        className={`subscribe-button primary`}
        onClick={handleClick}
    >
        {'Upgrade Now'}
    </button>
    if (alt == 3) return <button
        className={`subscribe-button primary`}
        onClick={handleClick}
    >
        {'Renew Subscription'}
    </button>
    if (alt == 4) return <a className='subscribe' onClick={handleClick}>Renew Plan</a>
    return <a className='subscribe' onClick={handleClick}>Upgrade Plan</a>;
};

export default CheckoutButton;