import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../AuthContextProvider'
import { useNavigate } from 'react-router-dom'
import AffiliateDashboard from './AffiliateDashboard'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { firestore } from '../../firebaseConfig'
import { Signup } from './Signup'

export default function AffiliateHandler() {
    const user = useContext(AuthContext)
    const navigate = useNavigate()
    const [proceed, setProceed] = useState(0)
    const [aff, setAffiliateCode] = useState(null)

    useEffect(() => {
        if (user === 0) return;

        if (!user) {
            setProceed(-1)
            return;
        }

        const { affiliate_id } = user;

        if (affiliate_id) {
            setProceed(1)
            setAffiliateCode(affiliate_id)
        }
        
        else setProceed(-1)
    }, [user])

    if (proceed === 0) return <></>
    else if (proceed === 1) return <AffiliateDashboard affiliateCode={aff} />
    else {
        return <Signup />
    }
}
