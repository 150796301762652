import React, { useContext, useEffect, useState } from 'react';
import PricingCard from './PricingCard.tsx';
import BillingToggle from './BillingToggle.tsx';
import { PricingHeader } from './PricingHeader.tsx';
import { PricingPlan } from '../types/subscription.ts';
import '../../styles/Pricing.css';
import Header from '../global/Header.jsx';
import { AuthContext } from '../../AuthContextProvider.js';
import CountdownBanner from './CountdownBanner.tsx';
import PromoCodeSection from './PromoModal.tsx';

const calculatePrice = (
    basePrice: number,
    isAnnual: boolean,
    annualDiscount: number
): number => {
    if (isAnnual) {
        return basePrice * (1 - annualDiscount);
    }
    return basePrice;
};

const PricingPage: React.FC = () => {
    const [isAnnual, setIsAnnual] = useState(true);
    const [currentPlan, setCurrentPlan] = useState<'free' | 'premium'>('free');

    const user = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;
        const { subscriptionStatus, is_annual } = user
        if (subscriptionStatus === 'Basic') setCurrentPlan('free')
        else {
            setCurrentPlan(subscriptionStatus?.toLowerCase())
            if (is_annual) setIsAnnual(true)
            else setIsAnnual(false)
        }
    }, [user])

    const premiumPrice = 14.99;
    const originalPrice = 24.99;
    const annualDiscount = 0.5; // 20% discount

    const handleSubscribe = (planType: 'free' | 'premium') => {
        setCurrentPlan(planType);
    };

    const freePlan: PricingPlan = {
        name: 'Free',
        price: 0,
        features: [
            'Access to AI chatbot',
            '3 free messages',
            'Basic features'
        ],
        isCurrentPlan: currentPlan === 'free',
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < 811) {
                document.body.style.overflowY = 'scroll';
            }
            // else {
            //     document.body.style.overflowY = 'hidden';
            //     console.log(">"+window.innerHeight)
            // }
            if (window.innerWidth < 577) {
                document.body.style.overflowY = 'scroll';
            }
            // else {
            //     document.body.style.overflowY = 'hidden';
            // }
        };

        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Run the function initially to set the correct state
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const premiumPlan: PricingPlan = {
        name: 'Premium',
        price: calculatePrice(premiumPrice, isAnnual, annualDiscount),
        originalPrice:
            isAnnual ?
                14.99
                :
                24.99
        ,
        features: [
            'Unlimited messages',
            'Early access to new features',
            'Priority support',
            'Advanced AI capabilities'
        ],
        isPremium: true,
        isCurrentPlan: currentPlan === 'premium',
        subscription: currentPlan === 'premium' ? {
            isAnnual: isAnnual,
            price: calculatePrice(premiumPrice, isAnnual, annualDiscount),
        } : undefined
    };

    const [testMode, setTestMode] = useState(false)
    const [promoCode, setPromoCode] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [submitted, setSubmitted] = useState(false)

    return (<>
        <div className="pricing-container" style={{
            fontFamily: "Open Sans"
        }}>
            <Header />
            <CountdownBanner />
            <PricingHeader testMode={testMode} setTestMode={setTestMode} />
            <BillingToggle isAnnual={isAnnual} onToggle={() => setIsAnnual(!isAnnual)} />
            <div className="pricing-cards">
                <PricingCard
                    plan={freePlan}
                    testMode={testMode}
                    billingPeriod={isAnnual ? 'year' : 'month'}
                    onSubscribe={() => handleSubscribe('free')}
                />
                <PricingCard
                    plan={premiumPlan}
                    testMode={testMode}
                    billingPeriod={isAnnual ? 'year' : 'month'}
                    onSubscribe={() => handleSubscribe('premium')}
                    promoCode={promoCode}
                    setShowModal={setShowModal}
                    submitted={submitted}
                />
            </div>
            {
                showModal &&
                <PromoCodeSection
                    onApplyPromo={() => { return true }}
                    setSubmitted={setSubmitted}
                    submitted={submitted}
                    promoCode={promoCode}
                    setPromoCode={setPromoCode}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            }
        </div>
    </>
    );
};

export default PricingPage;