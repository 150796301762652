import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { handleLike, handleDislike, handleCopy } from '../../utils/handlers';
import parseString from '../../utils/parseString';
import NoMsgs from './NoMsgs';
import data from '../../data.json'
// import { auth } from './firebaseConfig';
const { dislikeLimit } = data
const iHeight = 20

const ChatLog = ({ influencerID, sessionID, messages, setMessages,
    user, err, handleSubmit, setInp, inpRef }) => {

    // useEffect(() => {
    //     if (!sessionID || !user || !user?.uid) return;
    //     const messagesRef = collection(firestore, `chatSessions/${sessionID}/messages`);
    //     const q = query(messagesRef, orderBy('timestamp'));

    //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //         const firestoreMessages = querySnapshot.docs.map((doc) => ({
    //             id: doc.id,
    //             ...doc.data(),
    //         }));

    //         setMessages(prevMessages => {
    //             // Merge Firestore messages with local state, avoiding duplicates
    //             const mergedMessages = [...prevMessages];
    //             firestoreMessages.forEach(firestoreMsg => {
    //                 if (!mergedMessages.some(localMsg => localMsg.id === firestoreMsg.id)) {
    //                     mergedMessages.push(firestoreMsg);
    //                 }
    //             });
    //             return mergedMessages.sort((a, b) => a.timestamp - b.timestamp);
    //         });
    //     }, (error) => {
    //         console.error('Error fetching messages:', error);
    //     });

    //     return () => unsubscribe();
    // }, [sessionID, user, newID]);

    // useEffect(() => {
    //     console.log(sessionID, user, user?.uid, newID)
    //     if (!sessionID || !user || !user?.uid) return;
    //     const messagesRef = collection(
    //         firestore,
    //         `chatSessions/${sessionID}/messages`
    //     );

    //     const q = query(messagesRef, orderBy('timestamp'));

    //     const unsubscribe = onSnapshot(
    //         q,
    //         (querySnapshot) => {
    //             const msgs = querySnapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 ...doc.data(),
    //             }));
    //             setMessages(msgs);
    //         },
    //         (error) => {
    //             console.error('Error fetching messages:', error);
    //             console.log(sessionID, user?.uid)
    //         }
    //     );

    //     // Cleanup listener on unmount
    //     return () => unsubscribe();
    // }, [sessionID, user, newID]);

    const [check, setCheck] = useState(false)
    const [disliked, setDisliked] = useState(null)
    const [dislikeInp, setDislikeInp] = useState("")
    const [regenerating, setRegenerating] = useState(false);
    const [regenerateCooldown, setRegenerateCooldown] = useState(false);

    const handleLikeLocal = async (messageId, sessionId, userId, influencerId, messageContent) => {
        handleLike(messageId, sessionId, userId, influencerId, messageContent)
            .then(() => {
                setMessages(prevMessages =>
                    prevMessages.map(msg =>
                        msg.id === messageId
                            ? { ...msg, feedback: { ...msg.feedback, like: true } }
                            : msg
                    )
                );
            })
            .catch((err) => {
                console.log("Error: ", err)
            })
    };

    const handleDislikeLocal = async (messageId, messageContent) => {
        // Update local state immediately
        handleDislike(messageId, sessionID, user?.uid, influencerID, messageContent, dislikeInp)
            .then(() => {
                setMessages(prevMessages =>
                    prevMessages.map(msg =>
                        msg.id === messageId
                            ? { ...msg, feedback: { ...msg.feedback, like: false } }
                            : msg
                    )
                );
                setDisliked(null)
            }
            )
            .catch((err) => {
                console.log("Error: ", err)
            })

        // Call the actual handleDislike function

    };

    const handleRegenerate = () => {
        if (regenerateCooldown) return;

        setRegenerateCooldown(true);
        setRegenerating(true);

        // Remove the last message if it's from the AI (user === 0)
        if (messages.length > 0 && messages[messages.length - 1].user === 1) {
            setMessages(prevMessages => prevMessages.slice(0, -1));
        }

        handleSubmit(true);

        // Reset cooldown after 3 seconds
        setTimeout(() => {
            setRegenerateCooldown(false);
        }, 3000);
    };

    useEffect(() => {
        if (regenerating && messages.length > 0 && messages[messages.length - 1].user === 0) {
            setRegenerating(false);
        }
    }, [messages, regenerating]);

    return (
        <div className={`chat-log ${messages.length === 0 && "empty"}`}>
            {messages.length === 0 ?
                <NoMsgs inpRef={inpRef} influencerID={influencerID} setInp={setInp} user={user} />
                :
                <>
                    {messages.map((m, i) => (<>
                        {m.imgRef ? <div className="msgImg">
                            <img src={m.imgRef} /> </div> : <></>}
                        <motion.div
                            key={i}
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, transition: { duration: 0.15 } }}
                            className={`message ${m.user === 1 ? "sending" : "receiving"}`}
                            style={{
                                position: "relative",
                                whiteSpace: 'pre-wrap',
                                marginBottom: (
                                    (i < messages.length - 1) && (m.user === messages[i + 1].user)
                                ) && ".4em",
                                // paddingBottom: (m.showTime && m.user === 0) && "1.4em",
                            }}>

                            {!m.user ? parseString(m.content.trim()) : m.content.trim()}
                            <div className='ops'>
                                {
                                    check === m.id ?
                                        <svg width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path></svg>
                                        :
                                        <svg onClick={() => {
                                            handleCopy(m.content)
                                            setCheck(m.id)
                                            setTimeout(() => setCheck(false), 3000)
                                        }} width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 5C7 3.34315 8.34315 2 10 2H19C20.6569 2 22 3.34315 22 5V14C22 15.6569 20.6569 17 19 17H17V19C17 20.6569 15.6569 22 14 22H5C3.34315 22 2 20.6569 2 19V10C2 8.34315 3.34315 7 5 7H7V5ZM9 7H14C15.6569 7 17 8.34315 17 10V15H19C19.5523 15 20 14.5523 20 14V5C20 4.44772 19.5523 4 19 4H10C9.44772 4 9 4.44772 9 5V7ZM5 9C4.44772 9 4 9.44772 4 10V19C4 19.5523 4.44772 20 5 20H14C14.5523 20 15 19.5523 15 19V10C15 9.44772 14.5523 9 14 9H5Z" fill="currentColor"></path></svg>
                                }
                                {m.feedback ?
                                    <> {m.feedback.like ?
                                        <svg width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path d="M12.592 2.50386C12.8047 2.13014 13.2317 1.935 13.652 2.01942C15.5627 2.40314 16.7246 4.36079 16.1516 6.23085L15.303 9L17.0142 9C19.6409 9 21.5485 11.5079 20.8574 14.0525L19.4994 19.0525C19.0267 20.7927 17.4526 22 15.6562 22H9.96721C8.869 21.9979 7.97939 21.1033 7.97939 20V9H8.31734C8.67472 9 9.0047 8.80771 9.18201 8.49613L12.592 2.50386Z" fill="currentColor"></path><path d="M5.98763 9C4.33761 9 3 10.3431 3 12V19C3 20.6569 4.33761 22 5.98763 22H6.52055C6.18162 21.4116 5.98763 20.7286 5.98763 20V9Z" fill="currentColor"></path></svg>
                                        :
                                        <svg width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path d="M11.4079 21.4961C11.1953 21.8698 10.7683 22.0649 10.348 21.9805C8.4373 21.5968 7.27541 19.6391 7.84844 17.7691L8.69697 14.9999L6.98577 14.9999C4.35915 14.9999 2.45151 12.492 3.14262 9.94747L4.50063 4.94747C4.97329 3.20722 6.54741 1.99994 8.34378 1.99994H14.0328C15.131 2.00207 16.0206 2.89668 16.0206 3.99994V14.9999H15.6827C15.3253 14.9999 14.9953 15.1922 14.818 15.5038L11.4079 21.4961Z" fill="currentColor"></path><path d="M18.0124 14.9999C19.6624 14.9999 21 13.6568 21 11.9999V4.99994C21 3.34308 19.6624 1.99994 18.0124 1.99994H17.4794C17.8184 2.58829 18.0124 3.27136 18.0124 3.99994V14.9999Z" fill="currentColor"></path></svg>
                                    }
                                    </>
                                    : <>
                                        <svg onClick={() => handleLikeLocal(m.id, sessionID, user?.uid, influencerID, m.content)} width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z" fill="currentColor"></path></svg>
                                        <svg onClick={() =>
                                            setDisliked(m.id)
                                        } width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z" fill="currentColor"></path></svg>
                                    </>
                                }
                            </div>
                            {
                                disliked === m.id &&
                                <div className='dislikePrompt'>
                                    <span className='tellUsMore'>Tell us More:</span>
                                    <input placeholder='(optional)' value={dislikeInp} onChange={(e) => setDislikeInp(e.target.value)}
                                        maxLength={dislikeLimit} />
                                    <button className='submitDislike' onClick={() => {
                                        handleDislikeLocal(m.id, m.content)
                                    }}>Submit</button>
                                    <button className='close' onClick={() => setDisliked(null)}>
                                        <svg width={iHeight} height={iHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="i"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.63603 5.63604C6.02656 5.24552 6.65972 5.24552 7.05025 5.63604L12 10.5858L16.9497 5.63604C17.3403 5.24552 17.9734 5.24552 18.364 5.63604C18.7545 6.02657 18.7545 6.65973 18.364 7.05025L13.4142 12L18.364 16.9497C18.7545 17.3403 18.7545 17.9734 18.364 18.364C17.9734 18.7545 17.3403 18.7545 16.9497 18.364L12 13.4142L7.05025 18.364C6.65972 18.7545 6.02656 18.7545 5.63603 18.364C5.24551 17.9734 5.24551 17.3403 5.63603 16.9497L10.5858 12L5.63603 7.05025C5.24551 6.65973 5.24551 6.02657 5.63603 5.63604Z" fill="currentColor"></path></svg>
                                    </button>
                                </div>
                            }
                        </motion.div>
                    </>))}
                </>
            }
            {
                err &&
                <div className='message receiving error'>
                    <div style={{
                        display: "flex", gap: "1em", alignItems: "center"
                    }}>
                        <div>
                            <svg style={{ color: "red" }} stroke="currentColor" fill="#aaa" stroke-width="0" viewBox="0 0 15 15" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z" fill="currentColor"></path></svg>
                        </div>

                        <div>An error occurred while {regenerating ? 'regenerating' : 'generating'} the response. Please check your network connection and try again. If the issue continues, feel free to reach out to us at
                            {" "}<a className='aff-link' href='mailto:help@alphatwin.ai'>help@alphatwin.ai</a>{" "}
                            for further assistance.
                        </div>
                    </div>
                    <div className='reason'>
                        Error Message: "{err}"
                    </div>
                    <div className='ops' style={{
                        marginLeft: '.5em'
                    }}>
                        <svg
                            onClick={handleRegenerate}
                            fill={regenerateCooldown ? "#aaa" : "darkred"}
                            stroke-width="0"
                            version="1.1"
                            viewBox="0 0 17 17"
                            width={15}
                            height={15}
                            className={`i ${regenerateCooldown ? 'disabled' : ''}`}
                            style={{ cursor: regenerateCooldown ? 'not-allowed' : 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg">
                            <g></g>
                            <path d="M6 8h-6v-6h1v4.109c1.013-3.193 4.036-5.484 7.5-5.484 3.506 0 6.621 2.36 7.574 5.739l-0.963 0.271c-0.832-2.95-3.551-5.011-6.611-5.011-3.226 0.001-6.016 2.276-6.708 5.376h4.208v1zM11 9v1h4.208c-0.693 3.101-3.479 5.375-6.708 5.375-3.062 0-5.78-2.061-6.611-5.011l-0.963 0.271c0.952 3.379 4.067 5.739 7.574 5.739 3.459 0 6.475-2.28 7.5-5.482v4.108h1v-6h-6z">
                            </path>
                        </svg>
                    </div>
                </div>
            }
        </div >
    );
};

export default ChatLog;