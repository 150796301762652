import { firestore } from '../firebaseConfig'; // Import your Firebase configuration
import {
    doc,
    collection,
    serverTimestamp,
    writeBatch,
} from 'firebase/firestore';
import data from '../data.json'
const { dislikeLimit } = data

// Function to handle liking a message
export async function handleLike(
    messageID,
    chatSessionID,
    userID = 'none',
    influencerID,
    messageContent
) {
    // Initialize a batch operation
    const batch = writeBatch(firestore);
    // Reference to the message document
    const messageRef = doc(
        firestore,
        'chatSessions',
        chatSessionID,
        'messages',
        messageID
    );

    // console.log(messageRef.exists())

    // Update the message document with feedback
    batch.update(messageRef, {
        feedback: {
            like: true,
            explanation: null,
        },
    });

    // Reference to the Feedback collection
    const feedbackRef = collection(firestore, 'Feedback');

    const newFeedbackDocRef = doc(feedbackRef);

    // Add a new document to the Feedback collection
    const newFeedback = {
        messageID: messageID,
        userID: userID ? userID : "none",
        influencerID: influencerID,
        like: true,
        explanation: null,
        timestamp: serverTimestamp(),
        sessionID: chatSessionID,
        messageContent: messageContent
    }
    batch.set(newFeedbackDocRef, newFeedback);

    // Commit the batch operation
    await batch.commit();
}

// Function to handle disliking a message
export async function handleDislike(
    messageID,
    chatSessionID,
    userID = 'none',
    influencerID,
    messageContent,
    explanation = '',
) {
    // try {
    // Initialize a batch operation
    const batch = writeBatch(firestore);

    // Reference to the message document
    const messageRef = doc(
        firestore,
        'chatSessions',
        chatSessionID,
        'messages',
        messageID
    );

    // Update the message document with feedback
    batch.update(messageRef, {
        feedback: {
            like: false
        },
    });

    // Reference to the Feedback collection
    const feedbackRef = collection(firestore, 'Feedback');

    const newFeedbackDocRef = doc(feedbackRef);

    // Add a new document to the Feedback collection
    batch.set(newFeedbackDocRef, {
        messageID: messageID,
        userID: userID ? userID : "none",
        influencerID: influencerID,
        like: false,
        explanation: explanation.substring(0, dislikeLimit) || null,
        sessionID: chatSessionID,
        messageContent: messageContent,
        timestamp: serverTimestamp(),
    });

    // Commit the batch operation
    await batch.commit();
    // } catch (error) {
    //     console.error('Error recording dislike:', error);
    // }
}

export function handleCopy(text) {
    // Check if the browser supports the Clipboard API
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(function () {
            console.log('Text copied to clipboard');
        }).catch(function (err) {
            console.error('Could not copy text: ', err);
        });
    } else {
        // Fallback method for older browsers
        let textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard (fallback)');
        } catch (err) {
            console.error('Fallback method failed: ', err);
        }
        document.body.removeChild(textarea);
    }
}