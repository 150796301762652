export function setAffiliateCode(code) {
    const now = new Date();
    const expirationDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

    const affiliateData = {
        code,
        expiresAt: expirationDate.getTime(),
    };

    localStorage.setItem('affiliateData', JSON.stringify(affiliateData));
}

export function getAffiliateCode() {
    const storedData = localStorage.getItem('affiliateData');
    if (!storedData) return null;

    const { code, expiresAt } = JSON.parse(storedData);
    const now = new Date().getTime();
    if (now > expiresAt) {
        localStorage.removeItem('affiliateData');
        return null;
    }
    return code;
}