import React, { useEffect, useState } from 'react';
// import { Users, DollarSign, MousePointerClick, ArrowUpRight } from 'lucide-react';
import { StatCard } from './StatCard.tsx';
import { Chart } from './Chart';
// import { LinkStats } from './LinkStats';
import styles from '../../styles/Dashboard.module.css';
import Header from '../global/Header.jsx';
import { LinkDetails } from './LinkDetails.tsx';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig.js';

function getUTCYearMonth() {
    const currentDate = new Date(); // Get the current date
    const year = currentDate.getUTCFullYear(); // Get the UTC year
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Get UTC month (0-based, so add 1)

    return `${year}-${month}`;
}

function AffiliateDashboard({ affiliateCode }) {

    const [aff, setAff] = useState({})
    const [activeSubs, setActiveSubs] = useState(0)

    useEffect(() => {

        document.body.style.overflowY = 'scroll';

        const ref = doc(firestore, "affiliates", affiliateCode, "monthlyStats", getUTCYearMonth());

        const unsubscribe = onSnapshot(
            ref,
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = {
                        id: docSnapshot.id,
                        ...docSnapshot.data(),
                    };
                    setAff(data);
                } else {
                    console.log("Document does not exist");
                    setAff(null); // Handle the case where the document doesn't exist
                }
            },
            (error) => {
                console.error('Error fetching document:', error);
            }
        );

        const ref3 = doc(firestore, "affiliates", affiliateCode);

        const unsubscribe3 = onSnapshot(
            ref3,
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data()
                    setActiveSubs(data['activeSubs']);
                } else {
                    console.log("Document does not exist");
                    setActiveSubs(null); // Handle the case where the document doesn't exist
                }
            },
            (error) => {
                console.error('Error fetching document:', error);
            }
        );

        return () => {
            unsubscribe()
            unsubscribe3()
        };

    }, [])
    return (
        <>
            {/* <Navigation /> */}
            <Header />
            <main className={styles.main} style={{ overflowY: "scroll" }}>
                <div className={styles.container}>
                    <h3 className={styles.title} style={{
                        fontFamily: "Open Sans"
                    }}>Monthly Stats</h3>

                    <div className={styles.statsGrid}>
                        <StatCard
                            title="Total Clicks"
                            value={aff?.currentMonthVisitors ? aff?.currentMonthVisitors : 0}
                            change={12.5}
                            Icon={
                                0
                            }
                        />

                        <StatCard
                            title="Conversions"
                            value={aff?.currentMonthSubscriptions ? aff?.currentMonthSubscriptions : 0}
                            change={8.2}
                            Icon={
                                1
                            }
                        />

                        <StatCard
                            title="Revenue"
                            value={aff?.currentMonthMoneyMade ?
                                Math.round((aff?.currentMonthMoneyMade * 33)) / 100
                                : 0}
                            change={-2.4}
                            Icon={
                                2
                            }
                        />

                        <StatCard
                            title="Active Referrals"
                            value={activeSubs ? activeSubs : 0}
                            change={5.7}
                            Icon={
                                3
                            }
                        />

                    </div>

                    <div className={styles.section}>
                        <Chart />
                    </div>

                    <div className={styles.section}>
                        <LinkDetails aff={aff} affiliateCode={affiliateCode} />
                    </div>
                </div>
            </main>
        </>
    );
}

export default AffiliateDashboard;