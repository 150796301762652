import React from 'react'

export default function Models(
    { setShown, influencerID, setInfluencerID, isCollapsed, setIsCollapsed }
) {

    const handler = (id) => {
        setInfluencerID(id)
        setShown(1)
    }

    function handleForm() {
        const x = document.createElement("a");
        x.href = "https://forms.gle/wDZ4BnrcT98vyb9U7";
        x.target = "_blank"
        x.click()
    }

    return (
        <div className="card models" style={{ position: "relative" }}>
            <div className="text">
                <div className="icon collapse-icon"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    style={{
                        position: "absolute",
                        top: '.5em',
                        right: ".5em",
                        transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
                        transition: 'transform 0.3s ease'
                    }}>
                    <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                        <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                    </svg>
                </div>
                {!isCollapsed && "Your Models"}
            </div>
            <button className="icon fu" style={{
                position: "absolute",
                top: '1em',
                right: "1em",
                transform: `rotate(${180}deg)`,
                transition: 'transform 0.3s ease'
            }} onClick={() => {
                setShown(1)
            }}>
                <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                    <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                </svg>
            </button>

            {
                // !window.location.href.includes("aiguy") ? <>

                //     <div className={`profile hoverable ${influencerID === 'alex' && "selected"}`}>
                //         <img src="https://www.acquisition.com/hs-fs/hubfs/alexphoto-1.jpg?width=400&height=400&name=alexphoto-1.jpg" alt="" />
                //         <div className="desc">
                //             <div className="name">Coming Soon...</div>
                //             <div className="bio">Whose Next?</div>
                //         </div>
                //     </div>

                //     <div className={`profile hoverable ${influencerID === 'aiguy' && "selected"}`} onClick={() => handler("aiguy")}>
                //         <img src="https://yt3.googleusercontent.com/sETg5-Gg1Eg86vAj3Gocigb-14nlNXb3J5qCkh4wvxc3WWiY-Myx-ENfN4G-JYyiPUFajWyFqPg=s160-c-k-c0x00ffffff-no-rj" alt="" />
                //         <div className="desc">
                //             <div className="name">AI Guy</div>
                //             <div className="bio">YouTube Automation Expert</div>
                //         </div>
                //     </div>
                // </>
                //     :
                <>

                    <div className={`profile hoverable ${influencerID === 'aiguy' && "selected"}`} onClick={() => handler("aiguy")}>
                        <img src="https://yt3.googleusercontent.com/sETg5-Gg1Eg86vAj3Gocigb-14nlNXb3J5qCkh4wvxc3WWiY-Myx-ENfN4G-JYyiPUFajWyFqPg=s160-c-k-c0x00ffffff-no-rj" alt="" />
                        <div className="desc">
                            <div className="name">AI Guy</div>
                            <div className="bio">YouTube Automation Expert</div>
                        </div>
                    </div>

                    <div style={{ textDecoration: "none", border: "none" }} onClick={handleForm} className={`profile hoverable ${influencerID === 'alex' && "selected"}`}>
                        <img src="question.png" alt="" />
                        <div className="desc">
                            <div className="name">Coming Soon...</div>
                            <div className="bio">Who's Next? 👀</div>
                        </div>
                    </div>

                </>
            }


            <button className="newAlpha" onClick={() => alert("coming soon...")}>
                <img src='plus.svg' className='plus' /> New Alpha
            </button>
        </div>
    )
}
