import React, { useState, useRef, useEffect } from 'react';
import './PromoModal.css'

interface PromoCodeSectionProps {
    onApplyPromo: (code: string) => Promise<boolean>;
    promoCode: any;
    setPromoCode: any;
    showModal: any;
    setShowModal: any;

}

const PromoCodeSection: React.FC<PromoCodeSectionProps> = ({ onApplyPromo, promoCode, setPromoCode, showModal, setShowModal, setSubmitted,
    submitted
}) => {
    // const [showModal, setShowModal] = useState(false);
    // const [promoCode, setPromoCode] = useState('');
    const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setShowModal(false);
            }
        };

        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!promoCode.trim()) return;

        setIsLoading(true);
        setMessage(null);

        try {
            await new Promise(resolve => setTimeout(resolve, 3000));
            setMessage({ text: 'Your promo code has been submitted! If it is valid, you will receive a discount at checkout.', type: 'success' });
            // setPromoCode('');
            setSubmitted(true)
            setTimeout(() => setShowModal(false), 1500);
        } catch (error) {
            setMessage({ text: 'Error applying promo code', type: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="promo-code-section">

            {showModal && (
                <div className="pmodal-overlay">
                    <div className="promo-modal" ref={modalRef}>
                        <h2 style={{ color: "white" }}>Enter Promo Code</h2>
                        <form onSubmit={handleSubmit}>
                            <p className='pdesc'>
                                Enter your promo code below for a discount at checkout. <br />
                                <span>- Promo codes are <b>one-time use only</b>.</span> <br />
                                <span>- Monthly: Applies to your <b>first month</b>. Yearly: Get <b>2 months free</b>.</span>
                            </p>

                            <input
                                type="text"
                                className="promo-input"
                                placeholder="Enter promo code"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                maxLength={20}
                                autoFocus
                            />
                            {message && (
                                <p className={`promo-message ${message.type}`}>
                                    {message.text}
                                </p>
                            )}

                            <div className="pmodal-buttons">
                                <button
                                    type="button"
                                    className="pmodal-button secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="pmodal-button primary"
                                    disabled={!promoCode || !promoCode.trim() || isLoading || submitted}
                                >
                                    {isLoading ? 'Applying...' : 'Apply'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PromoCodeSection;