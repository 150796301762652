import React, { useContext, useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer/VideoPlayer.tsx';
import FadeIn from './animations/FadeIn.tsx';
import ConfettiEffect from './confetti/ConfettiEffect.tsx';
import './congratulations.css';
import { AuthContext } from '../../AuthContextProvider.js';
import { useNavigate } from 'react-router-dom';

const CongratulationsPage = () => {
    const user = useContext(AuthContext);
    const navigate = useNavigate();

    // If user is strictly null or undefined, navigate away.
    useEffect(() => {
        if (user == null) return navigate("/")
    }, [user, navigate]);

    // If user === 0, return nothing; otherwise render CongratulationsPage2
    if (user === 0) {
        return null; // or <></>
    }

    else {
        if (user && user.subscriptionStatus && user.subscriptionStatus === "Premium")
            return <CongratulationsPage2 />;
        else return navigate("/")
    }
};

const CongratulationsPage2 = () => {
    return (
        <div className="congratulations-container">
            <ConfettiEffect />
            <div className="content-wrapper">
                <FadeIn>
                    <h1 className="ctitle">🎉 Congratulations!</h1>
                </FadeIn>

                <FadeIn delay={200}>
                    <p className="csubtitle">
                        Welcome to <span className="username">AlphaTwin</span>! You've just unlocked the full potential of AI-powered YouTube automation.
                        Get ready to transform your content creation journey <b>today</b>.
                    </p>
                </FadeIn>

                <FadeIn delay={400}>
                    <div className="video-container">
                        <VideoPlayer src="/path/to/your/congratulations-video.mp4" />
                    </div>
                </FadeIn>

                <FadeIn delay={600}>
                    <div className="buttons-container">
                        <button
                            className="cbutton primary-button"
                            onClick={() => window.open('https://youtu.be/DX4Hik-l2a0', '_blank')}
                        >
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-play-circle "><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg> */}
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                            Watch Free Course
                        </button>

                        <button
                            className="cbutton secondary-button"
                            onClick={() => window.location.href = '/'}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-message-circle "><path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path></svg>
                            Start Chatting
                        </button>
                    </div>
                </FadeIn>
            </div>
        </div>
    );
};

export default CongratulationsPage;