import React from 'react';
import '../../styles/BillingToggle.css';

interface BillingToggleProps {
  isAnnual: boolean;
  onToggle: () => void;
}

const BillingToggle: React.FC<BillingToggleProps> = ({ isAnnual, onToggle }) => {
  return (
    <div className="billing-toggle-container">
      <span className={`billing-option ${!isAnnual ? 'active' : ''}`}>Monthly</span>
      <label className="toggle">
        <input
          type="checkbox"
          checked={isAnnual}
          onChange={onToggle}
        />
        <span className="slider"></span>
      </label>
      <span className={`billing-option ${isAnnual ? 'active' : ''}`}>
        Annual
        <span className="savings-badge">Save 50%</span>
      </span>
    </div>
  );
};

export default BillingToggle;