import React, { useEffect } from 'react';
import MetricsCard from './MetricsCard';
import styles from './CaseStudy.module.css';

const metrics = [
    { label: 'Video Views', value: '20,258' },
    { label: 'Revenue Generated', value: '$1,400' },
    { label: 'RPM', value: '$64' },
    { label: 'Conversion Rate', value: '5.9%' }
];


function CaseStudy() {
    useEffect(() => {
        document.body.style.background = "#f9fafb"
        document.body.style.overflow = "scroll"
    }, [])
    return (<>
        <article className={styles.container}>
            {/* Hero Section */}
            <header className={styles.header}>
                <div className={styles.heroImage}>
                    {/* <img
                        src="https://placehold.co/1200x400/1a365d/ffffff?text=AI-Powered+Mentorship"
                        alt="AI-Powered Mentorship Platform"
                    /> */}
                </div>
                <h1 className={styles.title}>
                    How "AI Guy" Transformed Viewers into Subscribers with AI-Powered Mentorship
                </h1>
                <p className={styles.subtitle}>
                    A Customer Success Story
                </p>
                <p style={{ position: "absolute", opacity: .4, marginTop: '2.5rem', fontSize: "10px" }}>
                    Written by: <u>Rishi Patel</u>. Published January 7th, 2025
                </p>

            </header>

            {/* Key Metrics Grid */}
            <div className={styles.metricsGrid}>
                {metrics.map((metric) => (
                    <MetricsCard key={metric.label} {...metric} />
                ))}
            </div>

            {/* Main Content */}
            <div className={styles.content}>
                <section className={styles.section}>
                    <h2 className={styles.heading}>Introduction</h2>
                    <p className={styles.text}>
                        In today's creator economy, influencers are constantly looking for ways to monetize their audiences.
                        While brand deals, affiliate links, and merchandise are common approaches, these products often lack
                        personalization, resulting in lower audience engagement. AlphaTwin addresses this challenge by enabling
                        creators to sell AI Mentors of themselves, transforming generic product offerings into personalized
                        learning experiences.
                    </p>
                    <iframe
                        src="https://www.youtube.com/embed/DX4Hik-l2a0"
                        title="AI Guy's Success Story"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        height="500"
                    ></iframe>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.heading}>The Challenge</h2>
                    <p className={styles.text}>
                        Egor Roslov, known online as "AI Guy", faced a common challenge despite having over 20,000 views
                        on his videos: converting passive viewers into active subscribers. Recognizing the need for a
                        sustainable revenue stream beyond traditional content creation, Egor sought a solution that would
                        allow him to scale his personal mentorship while maintaining authentic connections with his audience.
                    </p>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionImage}>
                        <img
                            src="app.png"
                            alt="AlphaTwin AI Mentorship Platform Interface"
                        />
                    </div>
                    <h2 className={styles.heading}>The Solution</h2>
                    <p className={styles.text}>
                        Through AlphaTwin's platform, AI Guy created an AI-powered chatbot clone that embodied his expertise
                        and personality. This innovative solution offered:
                    </p>
                    <ul className={styles.list}>
                        <li>Personalized YouTube automation insights</li>
                        <li>Interactive query responses</li>
                        <li>Guided digital course assistance</li>
                        <li>Scalable mentorship capabilities</li>
                    </ul>
                    <p className={styles.text}>
                        The implementation focused on organic YouTube content distribution with strategic calls-to-action,
                        allowing AI Guy to test the waters without significant upfront marketing investment.
                    </p>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionImage}>
                        <img
                            src="stats.png"
                            alt="Results and Analytics Dashboard"
                        />
                    </div>
                    <h2 className={styles.heading}>The Results</h2>
                    <p className={styles.text}>
                        The impact was immediate and significant. From a single video with 20,258 views, AI Guy generated:
                    </p>
                    <ul className={styles.list}>
                        <li>$1,400 in revenue (excluding potential subscription renewals)</li>
                        <li>$64 Revenue Per Mille (RPM)</li>
                        <li>2.6% Cost Per Mille (CPM)</li>
                        <li>5.9% conversion rate (upper end of B2C SaaS standards of 3-7%)</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.heading}>Key Takeaways</h2>
                    <p className={styles.text}>
                        AI Guy's success demonstrates the power of authentic audience engagement through AI-powered
                        personalization. By offering himself as the product rather than promoting third-party merchandise,
                        he established a deeper connection with his audience while creating a sustainable revenue stream
                        that continues to generate income even during content creation breaks.
                    </p>
                </section>


            </div>
        </article>
        <p className={styles.footer}>
            <a className={styles.subtitle} href="https://alphatwin.ai/">
                AlphaTwin Home
            </a>
            <a className={styles.subtitle} href="https://forms.gle/NRTRduiziApZuBKGA">
                Get your own AI Twin
            </a>
            <a className={styles.subtitle} href="https://alphatwin.ai/#contact">
                Contact Us
            </a>
        </p>
    </>
    );
}

export default CaseStudy;