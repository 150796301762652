import React, { useContext, useEffect, useState } from 'react';
import styles from '../../styles/Signup.module.css';
import Header from '../global/Header';
import axios from 'axios';
import { AuthContext } from '../../AuthContextProvider';

export function Signup() {

    const user = useContext(AuthContext)
    const [nav, setNav] = useState(false)

    useEffect(() => {
        // useNavigate("/affiliate")
    }, [nav])

    const handleJoinClick = () => {
        if (!user) {
            alert("You must first log in to become an affiliate!")
            return;
        }
        axios.post('https://api.alphatwin.ai/create-affiliate', {
            "user_id": user.uid
        })
            .then(() => window.location.reload())
    }

    document.body.style.overflowY = 'scroll'

    return (
        <>
            <Header absolute={true} />
            <main className={styles.main} style={{ fontFamily: "Open Sans", paddingTop: "80px" }}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" style={{ color: "#58009F" }} height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mouse-pointer-click _icon_s66p4_27"><path d="m9 9 5 12 1.8-5.2L21 14Z"></path><path d="M7.2 2.2 8 5.1"></path><path d="m5.1 8-2.9-.8"></path><path d="M14 4.1 12 6"></path><path d="m6 12-1.9 2"></path></svg>
                            <h1 className={styles.title}>Become an Affiliate</h1>
                            <p className={styles.subtitle}>
                                Join our affiliate program and start earning commissions today
                            </p>
                        </div>

                        <div className={styles.features}>
                            <div className={styles.feature} style={{ background: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(156,163,175)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users w-6 h-6 text-gray-400 mx-auto mb-2"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                <h3>33% Commission</h3>
                                <p>Earn competitive commissions on every successful referral</p>
                            </div>
                            <div className={styles.feature} style={{ background: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(156,163,175)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-cookie w-6 h-6 text-gray-400 mx-auto mb-2"><path d="M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5"></path><path d="M8.5 8.5v.01"></path><path d="M16 15.5v.01"></path><path d="M12 12v.01"></path><path d="M11 17v.01"></path><path d="M7 14v.01"></path></svg>
                                <h3>7-Day Cookie</h3>
                                <p>Long cookie duration to maximize your earning potential</p>
                            </div>
                            <div className={styles.feature} style={{ background: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(156,163,175)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wallet w-6 h-6 text-gray-400 mx-auto mb-2"><path d="M21 12V7H5a2 2 0 0 1 0-4h14v4"></path><path d="M3 5v14a2 2 0 0 0 2 2h16v-5"></path><path d="M18 12a2 2 0 0 0 0 4h4v-4Z"></path></svg>
                                <h3>Monthly Payout</h3>
                                <p>Get paid at the end of each month.</p>
                            </div>
                        </div>
                        <div className={styles.header}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" style={{ color: "#58009F" }} height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mouse-pointer-click _icon_s66p4_27"><path d="m9 9 5 12 1.8-5.2L21 14Z"></path><path d="M7.2 2.2 8 5.1"></path><path d="m5.1 8-2.9-.8"></path><path d="M14 4.1 12 6"></path><path d="m6 12-1.9 2"></path></svg> */}
                            <h1 className={styles.title}>Rewards Program</h1>
                            <p className={styles.subtitle}>
                                Unlock exclusive rewards as you reach new milestones
                            </p>
                        </div>

                        <div className={`${styles.features} ${styles.features2}`}>
                            <div className={styles.feature}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(168,85,247)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-award w-6 h-6 text-purple-500 mb-3"><circle cx="12" cy="8" r="6"></circle><path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11"></path></svg>
                                <h3>10 Users</h3>
                                <h4 style={{ color: "#C084FC" }} class="text-purple-400 font-medium mb-2">Free Lifetime Access</h4>
                                <p>Earn competitive commissions on every successful referral</p>
                            </div>
                            <div className={styles.feature}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(168,85,247)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-sparkles w-6 h-6 text-purple-500 mb-3"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg>
                                <h3>100 Users</h3>
                                <h4 style={{ color: "#C084FC" }} class="text-purple-400 font-medium mb-2">Bonus Pay</h4>
                                <p>Receive special bonus payments on top of commission</p>
                            </div>
                            <div className={styles.feature}>
                                <svg stroke="rgb(168,85,247)" fill="rgb(168,85,247)" stroke-width="0" viewBox="0 0 288 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path></svg>
                                <h3>500 Users</h3>
                                <h4 style={{ color: "#C084FC" }} class="text-purple-400 font-medium mb-2">Increased Comission</h4>
                                <p>Enjoy a higher percentage commission on all your referrals</p>
                            </div>
                            <div className={styles.feature}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(168,85,247)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plane w-6 h-6 text-purple-500 mb-3"><path d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"></path></svg>
                                <h3>1000 Users</h3>
                                <h4 style={{ color: "#C084FC" }} class="text-purple-400 font-medium mb-2">Founders Meetup</h4>
                                <p>Join an exclusive meetup with AI Guy and the founding team</p>
                            </div>
                        </div>

                        <button onClick={handleJoinClick} className={styles.joinButton}
                            style={{ marginBottom: 0 }}>
                            Join Now
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right "><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
                        </button>
                    </div>
                </div>
            </main>
        </>
    );
}