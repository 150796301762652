import React, { useContext, useEffect, useRef, useState } from 'react'
import data from '../../data.json'
import Modal from '../global/Modal';
import { AuthContext } from '../../AuthContextProvider';
import SubscriptionCTAModal from './SubscriptionCTAModal.tsx';

const { messageLimit, premiumMessageLimit } = data

function isTelegramWebApp() {
    // @ts-ignore
    return typeof TelegramWebviewProxy !== 'undefined';
}

export default function Input(
    { setInp, inpRef, handleSubmit,
        inp, disabled, setShowBrowserModal }
) {
    // if (!user) return null;

    const [isModalOpen, setModalOpen] = React.useState(false);
    const [rows, setRows] = useState(1)
    const sendRef = useRef()
    const formRef = useRef()
    const user = useContext(AuthContext)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            if (user) {

                if (user.subscriptionStatus === "Basic" && user.tokenCount <= 0) {
                    setShowUpgradeModal(true)
                }
                else handleSubmit()
            }
            else setModalOpen(true)
        }
    };

    useEffect(() => {
        const textarea = inpRef.current;
        sendRef.current.style.height = `${0}px`;
        textarea.style.height = 'auto';
        const defaultHeight = window.innerWidth > 477 ? 65 : 50;
        var newHeight = defaultHeight

        if (textarea.scrollHeight <= defaultHeight) {
            newHeight = defaultHeight
            textarea.style.borderRadius = '100px'
            textarea.style.borderTopRightRadius = '0'
            textarea.style.borderBottomRightRadius = '0'
            sendRef.current.style.borderRadius = '100px'
            sendRef.current.style.borderTopLeftRadius = '0'
            sendRef.current.style.borderBottomLeftRadius = '0'
        }
        else {
            newHeight = textarea.scrollHeight
            textarea.style.borderRadius = '3em'
            textarea.style.borderTopRightRadius = '0'
            textarea.style.borderBottomRightRadius = '0'
            sendRef.current.style.borderRadius = '3em'
            sendRef.current.style.borderTopLeftRadius = '0'
            sendRef.current.style.borderBottomLeftRadius = '0'
        }

        const h = Math.min(newHeight, window.innerWidth > 477 ? 330 : 200)

        textarea.style.height = `${h}px`;
        // setSendHeight(newHeight)
        // sendRef.current.style.height = `${newHeight}px`;
        if (sendRef.current) {
            sendRef.current.style.height = `${h}px`;
        }
    }, [inp]);

    return (
        <>
            <form className="bott" ref={formRef} style={{ zIndex: 999 }} onSubmit={e => {
                e.preventDefault()

                // Check for common in-app browsers
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                const inAppBrowserRegex = /(Instagram|FBAN|FBAV|Twitter|Telegram)/i;

                if (inAppBrowserRegex.test(userAgent) || isTelegramWebApp()) {
                    setShowBrowserModal(true);
                    return;
                }

                if (user) {
                    if (user.subscriptionStatus === "Basic" && user.tokenCount <= 0) {
                        setShowUpgradeModal(true)
                    }
                    else handleSubmit()
                }
                else setModalOpen(true)
            }}>
                <textarea style={{ resize: "none" }} onKeyDown={handleKeyDown} rows={rows} ref={inpRef} type="text" className='input'
                    value={inp} maxLength={
                        user?.subscriptionStatus === "Premium" ?
                            premiumMessageLimit : messageLimit
                    } onChange={e => setInp(e.target.value)}

                    // disabled={!user || !user.uid}

                    placeholder={
                        user && user.uid ?
                            'Type your message here...'
                            :
                            "You must log in to chat..."
                    } />
                <div className="sendContainer" ref={sendRef}>

                    <button className='send' disabled={disabled}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="17" xmlns="http://www.w3.org/2000/svg"><path d="m476.59 227.05-.16-.07L49.35 49.84A23.56 23.56 0 0 0 27.14 52 24.65 24.65 0 0 0 16 72.59v113.29a24 24 0 0 0 19.52 23.57l232.93 43.07a4 4 0 0 1 0 7.86L35.53 303.45A24 24 0 0 0 16 327v113.31A23.57 23.57 0 0 0 26.59 460a23.94 23.94 0 0 0 13.22 4 24.55 24.55 0 0 0 9.52-1.93L476.4 285.94l.19-.09a32 32 0 0 0 0-58.8z"></path></svg>
                        Send</button>
                </div>
            </form>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
            />
            {showUpgradeModal && (
                <SubscriptionCTAModal
                    remainingMessages={0}
                    onUpgrade={() => alert("HI")}
                    onClose={() => setShowUpgradeModal(false)}
                />
            )}
        </>
    )
}
