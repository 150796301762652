import React from 'react';
import './video-player.css';

interface VideoControlsProps {
    isPlaying: boolean;
    isMuted: boolean;
    currentTime: number;
    duration: number;
    onPlayPause: () => void;
    onMuteToggle: () => void;
    onTimeUpdate: (time: number) => void;
    onFullScreen: () => void;
}

const VideoControls = ({
    isPlaying,
    isMuted,
    currentTime,
    duration,
    onPlayPause,
    onMuteToggle,
    onTimeUpdate,
    onFullScreen
}: VideoControlsProps) => {
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="video-controls">
            <div className="progress-bar">
                <input
                    type="range"
                    min="0"
                    max={duration}
                    value={currentTime}
                    onChange={(e) => onTimeUpdate(Number(e.target.value))}
                />
                <div
                    className="progress-fill"
                    style={{ width: `${(currentTime / duration) * 100}%` }}
                />
            </div>

            <div className="controls-bar">
                <button className="control-button" onClick={onPlayPause}>
                    {isPlaying ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-pause "><rect width="4" height="16" x="6" y="4"></rect><rect width="4" height="16" x="14" y="4"></rect></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-play "><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                    }
                </button>

                <div className="time-display">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>

                <div className="right-controls">
                    <button className="control-button" onClick={onMuteToggle}>
                        {isMuted ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-volume-x "><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><line x1="22" x2="16" y1="9" y2="15"></line><line x1="16" x2="22" y1="9" y2="15"></line></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-volume2 "><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path><path d="M19.07 4.93a10 10 0 0 1 0 14.14"></path></svg>
                        }
                    </button>

                    <button className="control-button" onClick={onFullScreen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-maximize2 "><polyline points="15 3 21 3 21 9"></polyline><polyline points="9 21 3 21 3 15"></polyline><line x1="21" x2="14" y1="3" y2="10"></line><line x1="3" x2="10" y1="21" y2="14"></line></svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoControls;