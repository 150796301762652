import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import styles from '../../styles/Chart.module.css';

const data = Array.from({ length: 30 }, (_, i) => ({
    date: new Date(2024, 1, i + 1),
    clicks: Math.floor(Math.random() * 500) + 100,
    conversions: Math.floor(Math.random() * 50) + 10,
}));

export function Chart() {
    return (
        <div className={styles.chartContainer} style={{ fontFamily: "Open Sans" }}>
            <h3 className={styles.title}>Performance Overview (coming soon)</h3>
            <div className={styles.chartWrapper}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="colorClicks" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#58009F" stopOpacity={0.2} />
                                <stop offset="95%" stopColor="#58009F" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="colorConversions" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#34D399" stopOpacity={0.2} />
                                <stop offset="95%" stopColor="#34D399" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) => format(date, 'MMM d')}
                            tick={{ fontSize: 12, fill: '#535353' }}
                        />
                        <YAxis tick={{ fontSize: 12, fill: '#535353' }} />
                        <Tooltip
                            labelFormatter={(date) => format(date, 'MMM d, yyyy')}
                            contentStyle={{ backgroundColor: '#151618', border: '1px solid #535353' }}
                        />
                        <Area
                            type="monotone"
                            dataKey="clicks"
                            stroke="#58009F"
                            fillOpacity={1}
                            fill="url(#colorClicks)"
                        />
                        <Area
                            type="monotone"
                            dataKey="conversions"
                            stroke="#34D399"
                            fillOpacity={1}
                            fill="url(#colorConversions)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}