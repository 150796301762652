import React, { useContext, useState } from 'react';
import SubscriptionDetails from './SubscriptionDetails.tsx';
import { PricingPlan } from '../types/subscription.ts';
import axios from 'axios';
import { AuthContext } from '../../AuthContextProvider.js';
import UnsubscribeFeedbackModal from '../global/UnsubscribeFeedbackModal.jsx';
import CheckoutButton from '../Chat/CheckoutButton.jsx';
import data from '../../data.json'
import PromoCodeSection from './PromoModal.tsx';

interface PricingCardProps {
    plan: PricingPlan;
    testMode: boolean;
    billingPeriod: 'month' | 'year';
    onSubscribe: () => void;
}

var { monthlyPriceID, yearlyPriceID, monthlyTestPriceID, yearlyTestPriceID } = data

const findButton = (plan, billingPeriod, testMode, promoCode) => {
    if (plan.name === "Premium" && plan.isCurrentPlan) return <></>
    if (plan.name === "Premium" && !plan.isCurrentPlan) return <CheckoutButton alt={2} testMode={testMode} priceId={
        billingPeriod === 'year' ?
            // 'price_1QXeZlKwYi8l6iBvku4w6hNe'
            (testMode ? yearlyTestPriceID : yearlyPriceID)
            :
            // 'price_1PxbK0KwYi8l6iBvrAFic2Oc'
            (testMode ? monthlyTestPriceID : monthlyPriceID)
    } promoCode={promoCode} />
    if (plan.name === "Free" && plan.isCurrentPlan) return <button
        className={`subscribe-button secondary`}
        disabled={true}
    >
        Current Plan
    </button>
    if (plan.name === "Free" && !plan.isCurrentPlan) return <></>

}

const PricingCard: React.FC<PricingCardProps> = ({
    plan,
    testMode,
    billingPeriod,
    onSubscribe,
    promoCode,
    setShowModal,
    submitted

}) => {
    const user = useContext(AuthContext);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const handleUnsubscribeClick = () => {
        setShowFeedbackModal(true);
    };

    const handleUnsubscribeFeedback = async (feedback) => {
        try {
            // Here you can send the feedback to your backend/database
            // Proceed with actual unsubscribe action
            console.log(2)
            const res = await axios.post("https://api.alphatwin.ai/cancel_subscription", {
                "userId": user.uid,
                "reason": feedback,
                'email': user?.email
            })
            console.log(res)

        } catch (error) {
            console.error(error)
        }
    };

    let match
    if (user && user.subscriptionStatus == "Premium") match = (
        (user.is_annual && billingPeriod == 'year') ||
        (!user.is_annual && billingPeriod == 'month')
    )
    else match = true
    { console.log(submitted) }
    return (
        <>
            <div className={`pricing-card ${plan.isPremium ? 'premium' : ''}`}>
                {plan.isCurrentPlan && match && <div className="current-plan">Current Plan</div>}
                <h2 className="plan-name">{plan.name}</h2>
                {plan.name === "Premium" && !submitted &&
                    <button className='haveCode' onClick={() => {
                        setShowModal(true)
                    }}>Have a promo code?</button>
                }

                <div className="price-container">
                    {plan.originalPrice && (
                        <span className="original-price">${plan.originalPrice.toFixed(2)}</span>
                    )}
                    <span className="price">${plan.price.toFixed(2)}</span>
                    <span className="period">/month</span>
                </div>
                <div className="features">
                    {plan.features.map((feature, index) => (
                        <div key={index} className="feature">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check "><path d="M20 6 9 17l-5-5"></path></svg>
                            <span>{feature}</span>
                        </div>
                    ))}
                </div>
                {plan.isCurrentPlan && plan.subscription ? (
                    <SubscriptionDetails
                        testMode={testMode}
                        renewalDate={plan.subscription.renewalDate}
                        isAnnual={plan.subscription.isAnnual}
                        price={plan.subscription.price}
                        onUnsubscribe={handleUnsubscribeClick}
                        promoCode={promoCode}
                    />
                ) : (
                    findButton(plan, billingPeriod, testMode, promoCode)
                )}
                {plan.isPremium && billingPeriod === 'year' && (
                    <p className="annual-savings">Billed annually at ${(plan.price * 12).toFixed(2)}</p>
                )}
            </div>
            <UnsubscribeFeedbackModal
                isOpen={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
                onSubmit={handleUnsubscribeFeedback}
            />
        </>
    );
};

export default PricingCard;