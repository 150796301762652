import React, { useContext, useState } from 'react'
import { RewardsModal } from '../Chat/RewardsModal.tsx';
import { GlobalContext } from '../../GlobalContextProvider.js';

export default function FreeStuff({ isPremiumUser, cancel }) {
  const { showRewards, setShowRewards } = useContext(GlobalContext);

  const [isHovered, setIsHovered] = useState(false);

  return (<>
    <button className={`subscribe freeStuff ${(isPremiumUser && !cancel) ? 'Premium' : ''}`}
      style={{
        border: '1px solid gray', // Consolidated border
        background: isHovered ? '#3e3601' : '#171717', // Dynamic background on hover
        cursor: 'pointer',
        transition: 'background 200ms ease', // Smooth transition
        boxShadow: 'none', // Explicitly remove shadow
      }}
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false
      onClick={() => setShowRewards(true)}
    >
      🎁 Get Free Rewards!
    </button>

    <RewardsModal
      isOpen={showRewards}
      onClose={() => setShowRewards(false)}
      isPremiumUser={isPremiumUser}
    />
  </>
  )
}