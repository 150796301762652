import React, { useState, useEffect } from 'react';

export default function UnsubscribeFeedbackModal({ isOpen, onClose, onSubmit }) {
    const [isClosing, setIsClosing] = useState(false);
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [customReason, setCustomReason] = useState('');
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)

    const reasons = [
        "Too expensive",
        "Not satisfied with the bot's performance",
        "No longer need the service",
        "Technical issues",
        "Found a better alternative",
        "Tell us why..."  // Custom reason option
    ];

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onClose, 200);
    };

    const handleReasonClick = (reason) => {
        if (reason === "Tell us why...") {
            setShowCustomInput(true);
        } else {
            setSelected(reason)
            // onSubmit(reason);
            // handleClose();
        }
    };

    const handleCustomSubmit = (e) => {
        if (e.key === 'Enter' && customReason.trim()) {
            onSubmit(customReason);
            handleClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsClosing(false);
            setShowCustomInput(false);
            setCustomReason('');
            document.body.style.overflow = 'hidden';
        }

        const handleEscape = (e) => {
            if (e.key === 'Escape') handleClose();
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
            // document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={`modal-overlay ${isClosing ? 'fadeOut' : ''}`} onClick={handleClose}>
            <div className={`modal-content ${isClosing ? 'slideDown' : ''}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={handleClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <div className="modal-body" style={{
                    fontFamily: "Open Sans"
                }}>
                    <h2>Before You Go...</h2>
                    <p>Would you mind telling us why you're unsubscribing?</p>

                    {!showCustomInput ? (
                        <div className="feedback-options" style={{
                            display: "grid",
                            fontFamily: "Open Sans",
                            // gridTemplateRows: "1fr 1fr 1fr",
                            gridTemplateColumns: "1fr 1fr 1fr",
                        }}>
                            {reasons.map((reason) => (
                                <button
                                    key={reason}
                                    className={`${selected === reason && "selected"} reason-button`}
                                    onClick={() => handleReasonClick(reason)}
                                >
                                    {reason}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <input
                            type="text"
                            className="custom-reason-input"
                            placeholder="Press Enter to submit..."
                            value={customReason}
                            onChange={(e) => setCustomReason(e.target.value)}
                            onKeyDown={handleCustomSubmit}
                            autoFocus
                        />
                    )}
                    {
                        (selected || customReason) && (
                            <button
                                className='subscribe'
                                style={{ border: "1px solid black", fontSize: "large" }}
                                disabled={loading}
                                onClick={() => {
                                    setLoading(true)
                                    onSubmit(selected === "Tell us why..." ? customReason : selected)
                                        .then(() => {
                                            setLoading(false)
                                            handleClose()
                                        })
                                }}
                            >
                                {loading ? (
                                    <div className="spinner">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                ) : (
                                    'Submit :('
                                )}
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}