import React, { useRef, useState, useEffect } from 'react';
import VideoControls from './VideoControls.tsx';
import './video-player.css';

interface VideoPlayerProps {
  src: string;
}

const VideoPlayer = ({ src }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
      setIsLoaded(true);
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => video.removeEventListener('loadedmetadata', handleLoadedMetadata);
  }, []);

  const handlePlayPause = async () => {
    if (!videoRef.current || !isLoaded) return;

    try {
      if (isPlaying) {
        await videoRef.current.pause();
      } else {
        await videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error('Error toggling play/pause:', error);
    }
  };

  const handleMuteToggle = () => {
    if (!videoRef.current || !isLoaded) return;
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleTimeUpdate = (time: number) => {
    if (!videoRef.current || !isLoaded) return;
    videoRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleFullScreen = async () => {
    if (!containerRef.current) return;
    
    try {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      } else {
        await containerRef.current.requestFullscreen();
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  return (
    <div className="video-player" ref={containerRef}>
      <video
        ref={videoRef}
        className="video-element"
        onTimeUpdate={() => setCurrentTime(videoRef.current?.currentTime || 0)}
        onEnded={() => setIsPlaying(false)}
        onClick={handlePlayPause}
        src="https://firebasestorage.googleapis.com/v0/b/alphatwin-ed95e.firebasestorage.app/o/email%20trailer%20alphatwin.mp4?alt=media&token=f4aec0c4-d030-442f-9898-8babe1476860"
      >
        {/* <source src={src} type="video/mp4" /> */}
        Your browser does not support the video tag.
      </video>
      
      {isLoaded && (
        <VideoControls
          isPlaying={isPlaying}
          isMuted={isMuted}
          currentTime={currentTime}
          duration={duration}
          onPlayPause={handlePlayPause}
          onMuteToggle={handleMuteToggle}
          onTimeUpdate={handleTimeUpdate}
          onFullScreen={handleFullScreen}
        />
      )}
    </div>
  );
};

export default VideoPlayer;