import React, { useState, useEffect } from 'react';
import './CountdownBanner.css';

const CountdownBanner = () => {
    const calculateTimeLeft = () => {
        const targetDate = new Date('2026-01-13T11:59:59-05:00'); // January 11, 2025 11:59:59 PM EST
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            const days = 0; //Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            timeLeft = { days, hours, minutes, seconds };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="countdown-banner">
            <span className="countdown-text">🚀 Launch Day Sale Ends In:</span>
            <div className="countdown-timer">
                <span className="time-block">{timeLeft.days.toString().padStart(2, '0')}</span>
                <span className="time-separator">:</span>
                <span className="time-block">{timeLeft.hours.toString().padStart(2, '0')}</span>
                <span className="time-separator">:</span>
                <span className="time-block">{timeLeft.minutes.toString().padStart(2, '0')}</span>
                <span className="time-separator">:</span>
                <span className="time-block">{timeLeft.seconds.toString().padStart(2, '0')}</span>
            </div>
        </div>
    );
};

export default CountdownBanner;
