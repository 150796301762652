import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { auth, firestore } from "./firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

export const AuthContext = createContext()

export function AuthContextProvider({ children }) {

    const [currentUser, setCurrentUser] = useState(0)
    const [intermediaryUser, setIntermediaryUser] = useState(0)

    const setupUserListener = (uid) => {
        try {
            const userDocRef = doc(firestore, 'users', uid);
            // Listen for real-time updates
            const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setCurrentUser(u => ({ ...intermediaryUser, ...userData }))
                    // setLoading(false)
                } else {
                    console.log("No such document!");
                }
            });

            return unsubscribe; // Return the unsubscribe function to stop listening later

        } catch (error) {
            console.error("Error setting up user listener: ", error);
        }
    }

    useEffect(() =>
        onAuthStateChanged(auth, (user) => {
            setIntermediaryUser(user)
        }), [])

    useEffect(() => {
        // If still 0, do nothing yet (still waiting for onAuthStateChanged)
        if (intermediaryUser === 0) return;

        let unsubscribe;

        if (intermediaryUser?.uid) {
            // If we have a valid user object, set up Firestore listener
            unsubscribe = setupUserListener(intermediaryUser.uid);
        } else {
            // Otherwise, user is null => not logged in
            setCurrentUser(null);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [intermediaryUser]); // <-- full user object, not just .uid          

    return (
        <AuthContext.Provider value={currentUser}>
            {children}
        </AuthContext.Provider>
    )
}