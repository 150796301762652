import React from "react";
import EndlessMarquee from "./EndlessMarquee";
import data from "../../data.json";

export default function NoMsgs({ influencerID, setInp, inpRef }) {
    const suggestedQueries0 = data["suggestedQueries"][influencerID][0] || [];
    const suggestedQueries1 = data["suggestedQueries"][influencerID][1] || [];
    const fullQueries0 = data["fullQueries"][influencerID][0] || [];
    const fullQueries1 = data["fullQueries"][influencerID][1] || [];

    // For setting the input when user clicks, you can STILL wrap them in an onClick
    // by passing a callback or layering a div. 
    // But let's keep it simple at first:
    function onQueryClick(q) {
        setInp(q);
        inpRef.current.focus();
    }

    return (
        <div className="emptyContainer">
            <img src="logo6.png" className="logoImage pulsatingGlow" style={{ opacity: ".6", width: "250px", marginBottom: "2rem", marginTop: "2rem" }} />
            {/* The marquee of queries */}
            <EndlessMarquee
                suggestedQueries={suggestedQueries0}
                fullQueries={fullQueries0}
                speed={40000} // pass whatever speed you want (in ms)
                setInp={setInp} inpRef={inpRef}
            />
            <EndlessMarquee
                suggestedQueries={suggestedQueries1}
                fullQueries={fullQueries1}
                speed={40000} // pass whatever speed you want (in ms)
                setInp={setInp} inpRef={inpRef}
                reverse={true}
            />
        </div>
    );
}
