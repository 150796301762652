import { useState, createContext } from "react"

export const GlobalContext = createContext()

export function GlobalContextProvider({ children }) {

    const [showRewards, setShowRewards] = useState(0)

    return (
        <GlobalContext.Provider value={{ showRewards, setShowRewards }}>
            {children}
        </GlobalContext.Provider>
    )
}