import React from 'react';

export const PricingHeader: React.FC = ({ testMode, setTestMode }) => (
  <div className="pricing-header">
    <h1 className="pricing-title">Choose Your Plan
      {window.location.href.startsWith("http://localhost:3000/") ?
        <button style={{ background: testMode ? "green" : "red" }}
          onClick={() => setTestMode(t => !t)}>
          Test Mode
        </button>
        :
        <></>}
    </h1>
    <p className="pricing-subtitle">
      Start chatting with your favorite influencer's AI twin today
    </p>
  </div>
);