// EndlessMarquee.js
import React, { useEffect, useState } from "react";
import "./EndlessMarquee.css"; // We'll define the .inner, .wrapper, etc. here

const icons0 = [
    <svg key="icon0" stroke="currentColor" color="rgb(237, 98, 98)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>,
    <svg key="icon1" fill="rgb(118, 208, 235)" strokeWidth="0" viewBox="0 0 640 512" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>,
    <svg key="icon2" width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md" style={{ color: "rgb(226, 197, 65)" }}><path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.41496 3 5.5 5.92254 5.5 9.53846C5.5 11.8211 6.662 13.8298 8.42476 15H15.5752C17.338 13.8298 18.5 11.8211 18.5 9.53846C18.5 5.92254 15.585 3 12 3ZM14.8653 17H9.13473V18H14.8653V17ZM13.7324 20H10.2676C10.6134 20.5978 11.2597 21 12 21C12.7403 21 13.3866 20.5978 13.7324 20ZM8.12601 20C8.57004 21.7252 10.1361 23 12 23C13.8639 23 15.43 21.7252 15.874 20C16.4223 19.9953 16.8653 19.5494 16.8653 19V16.5407C19.0622 14.9976 20.5 12.4362 20.5 9.53846C20.5 4.82763 16.6992 1 12 1C7.30076 1 3.5 4.82763 3.5 9.53846C3.5 12.4362 4.93784 14.9976 7.13473 16.5407V19C7.13473 19.5494 7.57774 19.9953 8.12601 20Z" fill="currentColor"></path></svg>,
    <svg key="icon3" stroke="currentColor" color="rgb(98, 237, 98)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L15 8H9L12 2Z"></path><path d="M5 10H19V20H5V10Z"></path></svg>,
    <svg key="icon4" fill="rgb(237, 160, 98)" stroke="none" viewBox="0 0 24 24" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" strokeWidth="0"></path><path d="M12 7V12L16 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>,
    <svg key="icon5" stroke="currentColor" color="rgb(160, 98, 237)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><polygon points="12 2 2 22 22 22 12 2"></polygon><line x1="12" y1="12" x2="12" y2="18"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
];

const icons1 = [
    <svg key="icon0" stroke="currentColor" color="rgb(98, 237, 160)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>,
    <svg key="icon1" fill="rgb(237, 118, 208)" strokeWidth="0" viewBox="0 0 640 512" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>,
    <svg key="icon2" width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md" style={{ color: "rgb(160, 237, 98)" }}><path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.41496 3 5.5 5.92254 5.5 9.53846C5.5 11.8211 6.662 13.8298 8.42476 15H15.5752C17.338 13.8298 18.5 11.8211 18.5 9.53846C18.5 5.92254 15.585 3 12 3ZM14.8653 17H9.13473V18H14.8653V17ZM13.7324 20H10.2676C10.6134 20.5978 11.2597 21 12 21C12.7403 21 13.3866 20.5978 13.7324 20ZM8.12601 20C8.57004 21.7252 10.1361 23 12 23C13.8639 23 15.43 21.7252 15.874 20C16.4223 19.9953 16.8653 19.5494 16.8653 19V16.5407C19.0622 14.9976 20.5 12.4362 20.5 9.53846C20.5 4.82763 16.6992 1 12 1C7.30076 1 3.5 4.82763 3.5 9.53846C3.5 12.4362 4.93784 14.9976 7.13473 16.5407V19C7.13473 19.5494 7.57774 19.9953 8.12601 20Z" fill="currentColor"></path></svg>,
    <svg key="icon3" stroke="currentColor" color="rgb(237, 98, 160)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L15 8H9L12 2Z"></path><path d="M5 10H19V20H5V10Z"></path></svg>,
    <svg key="icon4" fill="rgb(98, 160, 237)" stroke="none" viewBox="0 0 24 24" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" strokeWidth="0"></path><path d="M12 7V12L16 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>,
    <svg key="icon5" stroke="currentColor" color="rgb(160, 237, 98)" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><polygon points="12 2 2 22 22 22 12 2"></polygon><line x1="12" y1="12" x2="12" y2="18"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
];

const getIcon = (index, reverse = false) => {
    const source = reverse ? icons1 : icons0;
    const idx = reverse ? source.length - 1 - index : index;
    return source[idx] || null; // Replace `null` with a default icon if desired
};

export default function EndlessMarquee({ suggestedQueries, fullQueries, setInp, inpRef, reverse }) {

    const [speed, setSpeed] = useState(19000)

    useEffect(() => {
        const updateSpeed = () => {
            if (window.innerWidth <= 768) {
                setSpeed(40000);  // mobile
            } else {
                setSpeed(70000);  // laptop/desktop
            }
        };

        window.addEventListener("resize", updateSpeed);
        updateSpeed(); // run once on mount to set initial speed

        return () => window.removeEventListener("resize", updateSpeed);
    }, []);

    return (
        <div className="inner">
            <div className="wrapper">
                <section style={{ "--speed": `${speed}ms` }} className={reverse && "reverse"}>
                    {suggestedQueries.map((query, index) => (
                        <div
                            className="query"
                            onClick={() => {
                                setInp(fullQueries[index]);
                                inpRef.current.focus();
                            }}
                        >
                            <div>
                                {getIcon(index, reverse)}
                            </div>
                            {query}
                        </div>
                    ))}
                </section>
                <section style={{ "--speed": `${speed}ms` }} className={reverse && "reverse"}>
                    {suggestedQueries.map((query, index) => (
                        <div
                            className="query"
                            onClick={() => {
                                setInp(fullQueries[index]);
                                inpRef.current.focus();
                            }}
                        >
                            <div>
                                {getIcon(index, reverse)}
                            </div>
                            {query}
                        </div>
                    ))}
                </section>
                <section style={{ "--speed": `${speed}ms` }} className={reverse && "reverse"}>
                    {suggestedQueries.map((query, index) => (
                        <div
                            className="query"
                            onClick={() => {
                                setInp(fullQueries[index]);
                                inpRef.current.focus();
                            }}
                        >
                            <div>
                                {getIcon(index, reverse)}
                            </div>
                            {query}
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
}

/**
 * queries?.map((q, i) =>
    <div className='query' onClick={() => {
        setInp(q)
        inpRef.current.focus()
    }}>
        <div>
            {getIcon(i)}
        </div>
        {q}
    </div>
)}
 */