import React, { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { getDeviceId } from '../../utils/deviceId';
// import { getEmailId } from '../../utils/email';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import History from './History';

import Header from '../global/Header';
import { AuthContext } from '../../AuthContextProvider';
import RightBar from './RightBar';
import TypingIndicator from './TypingIndicator';
import Models from './Models';
import Input from './Input';
import { getCurrentTime, getMessages } from '../../utils/helpers';
import MiddleTop from './MiddleTop';
import ChatLog from './ChatLog';
import data from '../../data.json'
import BrowserModal from '../global/BrowserModal';

import { auth, firestore } from '../../firebaseConfig'; // Adjust the import path to your Firebase config
import {
  collection,
  query,
  orderBy,
  getDocs
} from 'firebase/firestore';

var lastInp;
const { messageLimit, premiumMessageLimit } = data

export default function App() {

  const [messages, setMessages] = useState([
    // { content: "HI!", user: 0 }
  ])

  const [inp, setInp] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [testMode, setTestMode] = useState(
    // window.location.href.startsWith("http://localhost") || window.location.href.includes("192.168.")
    false
  )
  // const [email, setEmail] = useState("no-email")
  const [deviceId, setDeviceId] = useState(null)
  const [closed, setClosed] = useState(false)
  const [shown, setShown] = useState(window.innerWidth <= 764 ? 1 : 2)
  const [sessionID, setSessionID] = useState(uuidv4())
  const user = useContext(AuthContext)
  const [newID, setNewID] = useState(true)
  const [influencerID, setInfluencerID] = useState(
    "aiguy"
  )
  const [err, setErr] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showBrowserModal, setShowBrowserModal] = useState(false);

  useEffect(() => {
    if (!user) handlePen()
  }, [user])

  useEffect(() => {
    // console.log(sessionID)

  }, [sessionID])

  const inpRef = useRef()
  const recRef = useRef()
  const sendRef = useRef()
  const lastMessageRef = useRef(null);
  const prevMessagesRef = useRef([])
  const prevSessionID = useRef(uuidv4())

  useEffect(() => {
    // setReadMore(false)
    const temp = messages
    setMessages(prevMessagesRef.current)
    prevMessagesRef.current = [...temp]

    const t = sessionID
    const y = sessionID
    setSessionID(prevSessionID.current)
    prevSessionID.current = t

    // const x = uuidv4()
    // setSessionID(x)
    setNewID(true)
    lastMessageRef.current = y
  }, [influencerID])

  useEffect(() => {
    setDeviceId(getDeviceId())
    // setEmail(getEmailId())
  }, [])

  useEffect(() => {
    if (messages.length) {

      const lastMessage = messages[messages.length - 1]

      if (lastMessage.user === 0 && lastMessageRef.current === sessionID) {
        // console.log(lastMessageRef.current, sessionID)
        recRef.current.play();
      }

      lastMessageRef.current = sessionID;

      // Reset input if last message is from the user (user === 1)
      if (lastMessage.user === 1) {
        setInp("");
      }
    }
  }, [messages])

  useEffect(() => {

    // if (!disabled) {
    animateScroll.scrollToBottom({
      containerId: "chatContainer",
      duration: 300,
      delay: 0,
      smooth: "easeIn",
    });
    // }

  }, [messages]);

  function fetchData(msg) {

    if (testMode) return new Promise((resolve) => setTimeout(resolve, 1000, {
      data: {
        'output_list': getMessages(msg)
      }
    }))

    else return axios.post(`https://api.alphatwin.ai/api/main`, {
      body: {
        'UUID': user?.uid,
        'influencer_id': influencerID,
        'new_id': newID,

        "session_id": sessionID,
        "question": msg.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit),
        "device_id": deviceId,
        "email": user?.email ? user.email : null
      }
    })
  }

  useEffect(() => {
    if (messages.length && messages[messages.length - 1]?.user === 0 && disabled === sessionID) setDisabled(false)
  }, [messages])

  // const p = useRef()
  const latestSessionIDRef = useRef(sessionID);

  useEffect(() => {
    latestSessionIDRef.current = sessionID;
  }, [sessionID]);


  const handleSubmit = (retry = false) => {
    setErr(false);
    if (disabled || (!retry && inp.trim().length === 0)) return;
    if (inp.length) lastInp = inp;

    const newMessage = { content: retry ? lastInp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit) : inp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit), user: 1, id: Date.now(), time: getCurrentTime(), showTime: true };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInp("");
    setDisabled(sessionID);
    const s = sessionID

    // try {
    fetchData(retry ? lastInp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit) : inp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit))
      .then(response => {
        if (response.data && response.data["output_list"]) {
          sendMessagesSequentially(response.data["output_list"], s)
          // .then(() => {
          // recRef.current.play()
          setDisabled(false);
          setNewID(false);
          setTimeout(() => {
            inpRef.current && inpRef.current.focus();
          }, 100);
          // })
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data && err.response.data.message) setErr(err.response.data.message);
        else setErr(err.message)
        console.error(err)
        setDisabled(false)
      })
    // } catch (error) {
    //   console.error(error);
    // } finally {

    // }
  };

  const sendMessagesSequentially = async (messagesArray, s) => {
    if (s !== latestSessionIDRef.current) return;
    for (let i = 0; i < messagesArray.length; i++) {
      try {

        // await new Promise((resolve) => setTimeout(resolve, 1000));
        const newMessage = {
          user: 0,
          content: messagesArray[i].content ? messagesArray[i].content : messagesArray[i],
          id: messagesArray[i].id ? messagesArray[i].id : Date.now() + i,
          time: getCurrentTime(), showTime: i === messagesArray.length - 1
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        recRef.current.play();
      } catch (e) {
        console.err(e)
      }
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1300) setClosed(true)
  }, [])

  const handleHistClick = (c) => {
    setSessionID(c)
    setNewID(false)
    setShown(1)
  }

  useEffect(() => {
    setErr(false)
  }, [sessionID])

  const handlePen = () => {
    setNewID(true)
    const x = uuidv4()
    setSessionID(x)
    setMessages([])
    lastMessageRef.current = x
  }

  const [isWindows, setIsWindows] = useState(false);

  // function isTelegramWebApp() {
  //   // @ts-ignore
  //   return typeof TelegramWebviewProxy !== 'undefined';
  // }

  useEffect(() => {
    const platform = navigator.platform.toLowerCase();
    if (platform.indexOf('win') > -1) {
      setIsWindows(true);
    }

    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for common in-app browsers
    // const inAppBrowserRegex = /(Instagram|FBAN|FBAV|Twitter|Telegram)/i;

    // if (inAppBrowserRegex.test(userAgent) || isTelegramWebApp()) {
    //   setShowBrowserModal(true);
    // }
  }, []);

  useEffect(() => {
    if (!sessionID || !user || !user?.uid) {
      setMessages([]);
      return;
    }

    const fetchMessages = async () => {
      const messagesRef = collection(firestore, `chatSessions/${sessionID}/messages`);
      const q = query(messagesRef, orderBy('timestamp'));
      try {
        const querySnapshot = await getDocs(q);
        const fetchedMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(fetchedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
        setMessages([]);
      }
    };

    fetchMessages();

    // const messagesRef = collection(firestore, `chatSessions/${sessionID}/messages`);
    // const q = query(messagesRef, orderBy('timestamp'));

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const firestoreMessages = querySnapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));

    //   setMessages(firestoreMessages);
    // }, (error) => {
    //   console.error('Error fetching messages:', error);
    // });

    // return () => unsubscribe();
  }, [sessionID, auth.currentUser?.uid]);

  return (
    <>
      <audio src='B.mp3' ref={recRef} />
      <audio src='A.mp3' ref={sendRef} />

      <Header />

      <div className={`body ${isWindows && "windows-os"}`}>

        <div className={`leftBar ${!closed && "closedOff"} ${shown === 0 ? "shown" : "notShown"} ${isCollapsed && "collapse"}`}>
          <Models
            setShown={setShown}
            influencerID={influencerID}
            setInfluencerID={setInfluencerID}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <History
            setShown={setShown}
            handlePen={handlePen}
            influencerID={influencerID}
            setNewID={setNewID}
            sessionID={sessionID}
            setSessionID={setSessionID}
            user={user}
            handleHistClick={handleHistClick}
          />
        </div>
        <div className={`middleBar ${!closed ? "closedOff" : "x"} ${shown === 1 ? "shown" : "notShown"}`}>
          <div className="card chat">
            <MiddleTop isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} setShown={setShown} setClosed={setClosed} influencerID={influencerID} />
            <div className="convo" id="chatContainer">
              <ChatLog
                influencerID={influencerID}
                newID={newID} user={user} sessionID={sessionID}
                messages={messages} setMessages={setMessages}
                err={err} handleSubmit={handleSubmit} setInp={setInp}
                inpRef={inpRef} />
              {disabled === sessionID && <TypingIndicator />}
            </div>

            <Input setInp={setInp} inpRef={inpRef} user={user}
              handleSubmit={handleSubmit} inp={inp} disabled={disabled}
              setShowBrowserModal={setShowBrowserModal} />

          </div>
        </div>

        <RightBar
          closed={closed} influencerID={influencerID} setClosed={setClosed}
          shown={shown} setShown={setShown} testMode={testMode} setTestMode={setTestMode} />
      </div>
      <BrowserModal
        isOpen={showBrowserModal}
        onClose={() => setShowBrowserModal(false)}
      />
    </>
  )
}
